<template>
  <div>
    <span>
      <a-upload
        name="file"
        :multiple="true"
        action="#"
        accept=".xlsx"
        :showUploadList="false"
        @change="handleChangeFile"
      >
        <a-button type="primary" v-show="!disable"> <a-icon type="upload" /> 上传 </a-button>
        <a-button type="link" v-show="!disable" style="margin-left: 15px" @click="downloadTemplate">下载模板</a-button>
      </a-upload>
      <p v-for="item in merchantList" :key="item.merchantId" style="line-height: 13px">
        {{ item.merchantName }}
      </p>
    </span>
    <p v-show="!disable"><b style="color: red">*</b> 注：支持扩展名.xlsx</p>
  </div>
</template>

<script>
import XLSX from "xlsx";
  export default {
    data() {
      return {
        merchants: []
      }
    },
    props: {
      merchantList: {
        type: Array,
        required: true
      },
      disable: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      // 上传excel并解析数据
      handleChangeFile({ file }) {
        const reader = new FileReader();
        reader.onload = () => {
          const txt = reader.result;
          const wb = XLSX.read(txt, {
            type: "binary"
          });
          const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
          // 到json就可以获取到解析后的结果了
          const newData = json.map(item => {
            return {
              merchantId: item.商户id,
              merchantName: item.商户名称,
              displayName: item.展示商户名称,
              projectName: item.项目名称

            };
          }) ;
          try {
            if (newData && newData.length === 0) {
              this.$message.error("excel没有读到数据!")
              throw new Error()
            }
            newData.forEach(item => {
              if (!item.merchantId && !item.merchantName && !item.displayName && !item.projectName) {
                this.$message.error("excel数据不正确！")
                throw new Error()
              }
            })
          } catch (err) {
            return false;
          }
          this.merchantList = newData
          this.$emit('get-merchant-data', newData)
          // // 如果是战队PK
          // if(this.form.type === 2) {
          //   this.form.merchants = newData
          // } else {
          //   this.merchantData = newData;
          //   this.configForm.activityConfigMerchantList = this.merchantData;
          // }
        };
        if (file.status === "error") {
          reader.readAsBinaryString(file.originFileObj);
        }
      },
      // 下载模板
      downloadTemplate() {
          if(process.env.NODE_ENV === "production") {
            location.href = "https://oss-workplace-prod.innoecos.cn/picture-prod/07d8d17c4cc10000_%E5%95%86%E6%88%B7%E4%BF%A1%E6%81%AF%20.xlsx";
          } else {
            location.href = "https://oss-workplace-prod.innoecos.cn/picture-prod/07d8ce56fb810000_%E5%95%86%E6%88%B7%E4%BF%A1%E6%81%AF%202.xlsx";
          }

      },
    }
  }

</script>

