<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="关联项目" :required="true">
        <a-input
          :disabled="true"
          v-model="form.projectName"
        />
      </a-form-model-item>
      <a-form-model-item ref="pondId" label="关联奖池ID" prop="pondId">
        <a-select
          show-search
          placeholder="请选择奖池ID"
          option-filter-prop="children"
          style="width: 300px"
          :filter-option="filterOption"
          v-model="form.pondId"
          :disabled="disable"
        >
          <a-select-option v-for="item in jackPotList" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="关联关键词组">
        <a-select
          v-model="form.keywordId"
          placeholder="请选择关联关键词组"
          :disabled="disable"
        >
          <a-select-option v-for="item in allKeyWordGroup" :key="item.id">
            {{ item.keyWordGroupName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="适用商户">
        <MerchantExport :disable="disable" :merchantList="form.activityConfigMerchantList" @get-merchant-data="getMerchantData"/>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }" v-show="!disable">
        <a-button type="primary" @click="onSubmit">
          保存
        </a-button>
        <a-button style="margin-left: 10px;" @click="resetForm">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import MerchantExport from "./MerchantExport.vue";
import wangEditor from "wangeditor";
import { IMG_API } from "@/config";
import { selectJackpotListByProjectId, selectKeyWordGroupList } from "@/api/yearEndActivities";
export default {
  name: 'startRedEnvelopeConfig',
  components: {MerchantExport},
  props: {
    currentEditConfig: {
      type: Object,
      required: true
    },
    disable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      IMG_API,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      // 可关联奖池
      jackPotList: [],
      // 可关联词组
      allKeyWordGroup: [],
      form: {
        projectId: "",
        projectName: "",
        pondId: undefined,
        keywordId: "",
        activityConfigMerchantList: []
      },
      rules: {
        pondId: [
          { required: true, message: '请选择关联奖池ID', trigger: 'change' },
        ],
        // activityConfigMerchantList: [
        //   { required: true, message: '请上传适用商户', trigger: 'blur,change' },
        // ]
      },
    }
  },
  watch: {
    currentEditConfig: {
      handler(val) {
        console.log(this.currentEditConfig)
        if(val.projectId) {
          this.getJackpotListByProjectId(val.projectId)
          this.findKeyWordGroupList(val.projectId)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.form = {...this.form, ...this.currentEditConfig}
    })
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     * 根据项目id获取可关联奖池
     */
    async getJackpotListByProjectId(projectId) {
      const res = await selectJackpotListByProjectId(projectId)
      const { data, code } = res
      if(code === '200') {
        this.jackPotList = data && data.length > 0 && data.map(item => {
          return {
            ...item,
            name: `${item.id} - ${item.pondTitle}`
          }
        })
      }
    },
    /**
     * 查询所有关键词列表
     */
    findKeyWordGroupList(projectId) {
      selectKeyWordGroupList(projectId).then(res => {
        const { code, data } = res
        if (code === "200") {
          this.allKeyWordGroup = data.map(item => {
            return {
              ...item,
              keyWordGroupName: `${item.id} - ${item.keyWordGroupName}`
            }
          })
        }
      });
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const data = {
            ...this.currentEditConfig,
            ...this.form
          }
          this.$emit('addYearEndConfigData', data)
          this.$emit('close')
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$emit('close')
    },
    /**
     * 上传商户后获取数据
     */
    getMerchantData(data) {
      if(data && data.length) {
        this.form.activityConfigMerchantList = data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  z-index: -1 !important;
}
</style>
