<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="关联项目" :required="true">
        <a-input
          :disabled="true"
          v-model="currentEditConfig.projectName"
        />
      </a-form-model-item>
      <a-form-model-item ref="levelTop" label="锦鲤奖品价值" prop="levelTop">
        <a-input
          :max-length="8"
          v-model="form.levelTop"
          :disabled="disable"
        />
      </a-form-model-item>
      <a-form-model-item ref="levelA" label="等级树A" prop="levelA">
        <a-input
          :max-length="8"
          v-model="form.levelA"
          :disabled="disable"
        />
      </a-form-model-item>
      <a-form-model-item ref="levelB" label="等级树B" prop="levelB">
        <a-input
          :max-length="8"
          v-model="form.levelB"
          :disabled="disable"
        />
      </a-form-model-item>
      <a-form-model-item ref="levelA" label="等级树C" prop="levelC">
        <a-input
          :max-length="8"
          v-model="form.levelC"
          :disabled="disable"
        />
      </a-form-model-item>
      <a-form-model-item ref="levelD" label="等级树D" prop="levelD">
        <a-input
          :max-length="8"
          v-model="form.levelD"
          :disabled="disable"
        />
      </a-form-model-item>
      <a-form-model-item ref="levelE" label="等级树E" prop="levelE">
        <a-input
          v-model="form.levelE"
          :disabled="disable"
          :max-length="8"
        />
      </a-form-model-item>
<!--      <a-form-model-item ref="activityConfigMerchantList" label="适用商户">-->
<!--        <MerchantExport :disable="disable" :merchantList="form.activityConfigMerchantList"  @get-merchant-data="getMerchantData"/>-->
<!--      </a-form-model-item>-->
      <a-form-model-item ref="rule" label="活动规则" prop="rule">
        <div class="content">
          <div id="editer"></div>
        </div>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }" v-show="!disable">
        <a-button type="primary" @click="onSubmit">
          保存
        </a-button>
        <a-button style="margin-left: 10px;" @click="resetForm">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
  import MerchantExport from "./MerchantExport.vue";
  import wangEditor from "wangeditor";
  import { IMG_API } from "@/config";
  export default {
    name: 'startMoneyTreeConfig',
    components: {MerchantExport},
    props: {
      currentEditConfig: {
        type: Object,
        required: true
      },
      disable: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        IMG_API,
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        other: '',
        form: {
          // 锦鲤奖品价值
          levelTop: '',
          // 等级树A
          levelA: '',
          // 等级树B
          levelB: '',
          // 等级树C
          levelC: '',
          // 等级树D
          levelD: '',
          // 等级树E
          levelE: '',
          // 适用商户集合
          activityConfigMerchantList: [],
          // 活动规则
          rule: ''
        },
        rules: {
          levelTop: [
            { required: true, message: '请输入锦鲤奖品价值', trigger: 'blur' },
          ],
          levelA: [
            { required: true, message: '请输入等级树A奖品价值', trigger: 'blur' },
          ],
          levelB: [
            { required: true, message: '请输入等级树B奖品价值', trigger: 'blur' },
          ],
          levelC: [
            { required: true, message: '请输入等级树C奖品价值', trigger: 'blur' },
          ],
          levelD: [
            { required: true, message: '请输入等级树D奖品价值', trigger: 'blur' },
          ],
          levelE: [
            { required: true, message: '请输入等级树E奖品价值', trigger: 'blur' },
          ],
          rule: [
            { required: true, message: '请输入活动规则', trigger: 'change,blur' },
          ]
        },
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.createEditor();
        this.form = {...this.form, ...this.currentEditConfig}
      })
    },
    methods: {
      onSubmit() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            const data = {
              ...this.currentEditConfig,
              ...this.form
            }
            this.$emit('addStartMoneyTreeData', data)
            this.$emit('close')
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm() {
        this.$emit('close')
      },
      /**
       * 上传商户后获取数据
       */
      getMerchantData(data) {
          if(data && data.length) {
            this.form.activityConfigMerchantList = data
          }
      },
      /**
       * 创建富文本
       */
      createEditor() {
        const _this = this;
        const editor = new wangEditor(`#editer`);
        // 配置 onchange 回调函数，将数据同步到 vue 中
        editor.config.onchange = (newHtml) => {
          this.form.rule = newHtml;
        };
        editor.config.menus = [
          "fontName",
          "fontSize",
          "foreColor",
          "backColor",
          "underline",
          "italic",
          "bold",
          "justify",
          "splitLine",
          "undo",
          "redo",
          "list",
          "image",
          "video"
        ];
        editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
        editor.config.uploadImgServer = this.IMG_API + "/oss/files";
        editor.config.uploadImgShowBase64 = false;
        editor.config.showLinkImg = false;
        editor.config.showLinkVideo = false;
        editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
        editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
        editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
        editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
        // editor.config.uploadVideoAccept = ['mp4']
        editor.config.uploadFileName = "file";
        editor.config.uploadVideoName = "file";
        editor.config.uploadVideoTimeout = 60000;
        editor.config.uploadImgTimeout = 60000;
        editor.config.uploadVideoHooks = {
          // 上传视频之前
          before: function(xhr, editor, files) {
            console.log(xhr);
            console.log(editor);
            console.log(files);
          },
          // 视频上传并返回了结果，视频插入已成功
          success: function(xhr) {
            // this.$message.success('上传视频成功')
          },
          // 视频上传并返回了结果，但视频插入时出错了
          fail: function(xhr, editor, resData) {
            _this.$message.error("上传请求失败");
          },
          // 上传视频出错，一般为 http 请求的错误
          error: function(xhr, editor, resData) {
            _this.$message.error("上传请求失败");
          },
          // 上传视频超时
          timeout: function(xhr) {
            _this.$message.error("上传请求超时");
          },
          // 视频上传并返回了结果，想要自己把视频插入到编辑器中
          // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
          customInsert: function(insertVideoFn, result) {
            let videoHTML =
              "&nbsp;<video poster=\"" +
              result.redirect_uri +
              "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto\" src=\"" +
              result.redirect_uri +
              "\" controls style=\"max-width:100%\"></video>&nbsp;";
            editor.cmd.do("insertHTML", videoHTML);
            // result 即服务端返回的接口
            // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
            // insertVideoFn(result.redirect_uri)
          }
        };
        editor.config.uploadImgHooks = {
          // 上传视频之前
          before: function(xhr) {
          },
          // 视频上传并返回了结果，视频插入已成功
          success: function(xhr) {
            // this.$message.success('上传视频成功')
          },
          // 视频上传并返回了结果，但视频插入时出错了
          fail: function(xhr, editor, resData) {
            _this.$message.error("上传请求失败");
          },
          // 上传视频出错，一般为 http 请求的错误
          error: function(xhr, editor, resData) {
            _this.$message.error("上传请求失败");
          },
          // 上传视频超时
          timeout: function(xhr) {
            _this.$message.error("上传请求超时");
          },
          // 视频上传并返回了结果，想要自己把视频插入到编辑器中
          // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
          customInsert: function(insertVideoFn, result) {
            // result 即服务端返回的接口
            // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
            insertVideoFn(result.redirect_uri);
          }
        };
        editor.config.customAlert = function(s, t) {
          switch (t) {
            case "success":
              _this.$message.success(s);
              break;
            case "info":
              _this.$message.info(s);
              break;
            case "warning":
              _this.$message.warning(s);
              break;
            case "error":
              _this.$message.error(s);
              break;
            default:
              _this.$message.info(s);
              break;
          }
        };
        editor.config.placeholder = "请输入";
        editor.config.zindex = -1;
        // 创建编辑器
        editor.create();
        this.editor = editor;
        setTimeout(() => {
          this.editor.txt.html(this.form.rule);
        }, 1000);
        // 查看的时候禁用富文本
        if (this.$route.query.type === "view") {
          this.editor.disable();
        } else {
          this.editor.enable();
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
.content {
  z-index: -1 !important;
}
</style>
