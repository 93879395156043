import moment from "moment";

export const activitiesType = [
    {
        label: '开工发财树',
        value: 2
    },
    {
        label: '战队PK',
        value: 1
    },
    {
        label: '年终活动',
        value: 0
    },
    {
        label: '开工红包',
        value: 3
    }
]

/**
 * 发财树等级
 */
export const moneyTreeGrade = [
    {
        label: '等级树A',
        value: 'A'
    },
    {
        label: '等级树B',
        value: 'B'
    },
    {
        label: '等级树C',
        value: 'C'
    },
    {
        label: '等级树D',
        value: 'D'
    },
    {
        label: '等级树E',
        value: 'E'
    }
]

/**
 * 发财树table的cloum
 */
export const moneyTreeColumns= [
    {
        title: "活动ID",
        dataIndex: "activityId",
        key: "activityId"
    },
    {
        title: "用户ID",
        dataIndex: "userId",
        key: "userId"
    },
    {
        title: "用户手机号",
        dataIndex: "phoneNumber",
        key: "phoneNumber"
    },
    {
        title: "所属项目",
        dataIndex: "projectName",
        key: "projectName"
    },
    {
        title: "发财树等级",
        dataIndex: "prizeType",
        key: "prizeType",
        customRender(text) {
            if(text) {
                return `等级树${text}`
            } else {
                return '-'
            }

        }
    },
    {
        title: "成长值比例排名",
        dataIndex: "growRatio",
        key: "growRatio",
        customRender(text) {
            return `${text}%`
        }
    },
    {
        title: "任务成长值",
        dataIndex: "growValue",
        key: "growValue"
    },
    {
        title: "来源",
        dataIndex: "sourceType",
        key: "sourceType"
    },
    {
        title: "参与发财树时间",
        dataIndex: "getDate",
        key: "getDate",
        customRender(text) {
            return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-'
        }
    },
    {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
    },
]
