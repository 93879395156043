<template>
  <div>
    <ds-header :title="title" />
    <div>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="type" label="活动类型" prop="type">
            <a-select
              v-model="form.type"
              placeholder="请选择活动类型"
              style="width: 300px"
              :disabled="disable"
            >
              <a-select-option v-for="item in activitiesType" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item ref="activityTitle" label="活动名称" prop="activityTitle">
          <a-input
            v-model="form.activityTitle"
            placeholder="请输入活动名称"
            :disabled="disable"
            :max-length="30"
            @blur="
          () => {
            $refs.activityTitle.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item ref="activityTime" label="活动有效期" prop="activityTime">
          <a-range-picker format="YYYY-MM-DD HH:mm" :disabled="disable" @change="activityTimeChange" show-time
                          v-model="form.activityTime" />
        </a-form-model-item>
        <a-form-model-item ref="shareImg" label="分享图片" prop="shareImg">
          <a-upload
            :disabled="disable"
            name="file"
            list-type="picture-card"
            :action="IMG_API + '/oss/files'"
            class="avatar-uploader"
            :showUploadList="{ showPreviewIcon: false, showRemoveIcon: true }"
            :file-list="imgList"
            @change="handleChangeImg"
            :before-upload="imgBeforeUpload"
          >
            <div v-if="imgList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <p class="tip"><span style="margin-right: 5px;color: red">*</span>图片必须是1：1、大小不能超过200KB，格式为PNG、JPG。</p>
        </a-form-model-item>
        <a-form-model-item ref="shareInfo" label="分享简介" prop="shareInfo">
          <a-textarea
            :disabled="disable"
            v-model="form.shareInfo"
            placeholder="请输入分享时展示的简介..."
            @blur="
          () => {
            $refs.shareInfo.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item v-if="form.type === 0" style="z-index: 1" ref="activityRule" label="活动规则" prop="activityRule">
          <div class="content">
            <div id="editer"></div>
          </div>
        </a-form-model-item>
        <a-form-model-item ref="theirProject" label="所属项目" prop="theirProject">
          <a-select
            :disabled="disable"
            v-model="form.theirProject"
            mode="multiple"
            :filterOption="false"
            @change="projectOnChange"
          >
            <a-select-option v-for="item in projectList" :key="item.id">
              {{ item.project_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--开工发财树配置-->
        <a-form-model-item v-if="form.type === 2 && form.theirProject && form.theirProject.length" label="活动信息配置" prop="moneyTreeConfig">
          <div class="activity-config-box">
            <ul v-for="(item, index) in activitiesConfig" :key="index">
              <li>{{ item.projectName }}</li>
              <li>活动规则：
                <div class="rule-box" v-html="item.rule"></div>
              </li>
              <li class="config-but">
                <a-button v-show="!disable" size="small" type="link" @click="startMoneyTreeEdit(item,index)">编辑</a-button>
                <a-button v-show="disable" size="small" type="link" @click="startMoneyTreeEdit(item,index)">查看</a-button>
              </li>
            </ul>
          </div>
        </a-form-model-item>

        <!--开工红包开工红包配置-->
        <a-form-model-item v-if="form.type === 3 && form.theirProject && form.theirProject.length" label="活动信息配置" prop="startRedEnvelopeConfig">
          <div class="activity-config-box">
            <ul v-for="(item, index) in activitiesConfig" :key="index">
              <li>{{ item.projectName }}</li>
              <li>关联奖池：{{ item.pondId }}</li>
              <li class="config-but">
                <a-button v-show="!disable" size="small" type="link" @click="startRedEnvelopeEdit(item,index)">编辑</a-button>
                <a-button v-show="disable" size="small" type="link" @click="startRedEnvelopeEdit(item,index)">查看</a-button>
              </li>
            </ul>
          </div>
        </a-form-model-item>
        <!--年终活动-->
        <a-form-model-item v-if="form.type === 0 && form.theirProject && form.theirProject.length" label="奖池&关键词配置" prop="yearEndActivityConfig">
          <div class="activity-config-box">
            <ul v-for="(item, index) in activitiesConfig" :key="index">
              <li>{{ item.projectName }}</li>
              <li>关联奖池：{{ item.pondId }}</li>
              <li>关联关键词组： {{ item.keywordId }}</li>
              <li class="config-but">
                <a-button v-show="!disable" size="small" type="link" @click="yearEndActivityEdit(item,index)">编辑</a-button>
                <a-button v-show="disable" size="small" type="link" @click="yearEndActivityEdit(item,index)">查看</a-button>
              </li>
            </ul>
          </div>
        </a-form-model-item>
        <!--战队pK活动配置-->
        <a-form-model-item v-if="form.type === 1 && form.theirProject && form.theirProject.length" label="活动配置" prop="teamPkConfig">
          <div class="activity-config-box">
            <ul v-for="(item, index) in activitiesConfig" :key="index">
              <li>{{ item.projectName }}</li>
              <li class="config-but">
                <a-button v-show="!disable" size="small" type="link" @click="editActivityConfig(item,index)">编辑</a-button>
                <a-button v-show="disable" size="small" type="link" @click="editActivityConfig(item,index)">查看</a-button>
              </li>
            </ul>
          </div>
        </a-form-model-item>

        <a-form-model-item label="指定的i友圈话题ID" v-if="form.type === 1 || form.type === 2" prop="topicId">
          <a-input
            v-model="form.topicId"
            placeholder="请输入指定的i友圈话题ID"
            :disabled="disable"
            @blur="
          () => {
            $refs.activityTitle.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item ref="merchants" label="适用商户">
          <MerchantExport :disable="disable" :merchantList="form.merchants"  @get-merchant-data="getMerchantData"/>
        </a-form-model-item>
        <a-form-model-item label="商户范围" v-if="form.type === 1" prop="merchants">
          <span>
          <div v-show="!disable">
            <a-upload
              name="file"
              :multiple="true"
              action="#"
              accept=".xlsx"
              :showUploadList="false"
              @change="handleChangeFile"
            >
            <a-button type="primary"> <a-icon type="upload" /> 上传 </a-button>
            <a-button type="link" style="margin-left: 15px" @click="downloadTemplate">下载模板</a-button>
          </a-upload>
          </div>
          <p v-for="item in form.merchants" :key="item.merchantId" style="line-height: 13px">
            {{ item.merchantName }}
          </p>
          </span>
          <p v-show="!disable"><b style="color: red">*</b> 注：支持扩展名.xlsx</p>
        </a-form-model-item>
        <a-form-model-item ref="status" label="状态" prop="status">
          <a-radio-group :disabled="disable" name="radioGroup" v-model="form.status" :default-value="0">
            <a-radio :value="0">
              启用
            </a-radio>
            <a-radio :value="1">
              禁用
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button v-show="!disable" type="primary" @click="onSubmit">
            保存
          </a-button>
          <a-button style="margin-left: 10px;" @click="back">
            {{ disable ? "返回" : "取消" }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal

      :visible="visible"
      @ok="handleOk"
      :width="800"
      @cancel="handleCancel"
    >

    </a-modal>
    <a-modal
      :title="`${disable ? '查看' : '编辑'}`"
      :footer="null"
      :visible="isShowConfig"
      :width="800"
      @cancel="closeActivityConfig"
      :destroyOnClose="true"
    >
      <!--发财树活动-->
      <StartMoneyTreeConfig v-if="form.type === 2" :disable="disable" :currentEditConfig="currentEditConfig" @close="closeModel" @addStartMoneyTreeData="addStartMoneyTreeData"/>
      <!--战队PK-->
      <ActivityConfig v-if="form.type === 1" :disable="disable" ref="activityConfig" :currentEditConfig="currentEditConfig" @close="closeModel" @addActivityData="addActivityData"/>
      <!--开工红包-->
      <StartRedEnvelope v-if="form.type === 3" :disable="disable" :currentEditConfig="currentEditConfig" @close="closeModel" @addRedEnvelopeData="addRedEnvelopeData"/>
      <YearEndActivity v-if="form.type === 0" :disable="disable" :currentEditConfig="currentEditConfig" @close="closeModel" @addYearEndConfigData="addYearEndConfigData"/>

    </a-modal>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import { projectName } from "@/api/modularManagement";
import moment from "moment";
import wangEditor from "wangeditor";
import XLSX from "xlsx";
import { activitiesType } from '../../index'
import {
  selectKeyWordGroupList,
  updateYearEndActivity,
  insertYearEndActivity,
  selectYearEndActivityById,
  selectJackpotListByProjectId
} from "@/api/yearEndActivities";

import ActivityConfig from "../components/ActivityConfig.vue";
import StartMoneyTreeConfig from "../components/StartMoneyTreeConfig.vue";
import StartRedEnvelope from '../components/StartRedEnvelope.vue'
import YearEndActivity from "../components/YearEndActivity.vue";
import yearEndActivity from "@/views/yearEndActivities/activityManagement/components/YearEndActivity.vue";
import MerchantExport from "@/views/yearEndActivities/activityManagement/components/MerchantExport.vue";
const options = [
  { label: "企业认证", value: "1" },
  // { label: '当日通行信息', value: '2' },
  // { label: '当日消费信息', value: '3' },
  { label: "首次消费&通行信息", value: "4" }
];
export default {
  name: "index",
  computed: {
    yearEndActivity() {
      return yearEndActivity
    }
  },
  components: {
    MerchantExport,
    ActivityConfig,
    StartMoneyTreeConfig,
    StartRedEnvelope,
    YearEndActivity
  },
  data() {
    return {
      title: "新增",
      moment,
      visible: false,
      excelData: "",
      // 活动配置信息
      activitiesConfig: [],
      // 商户数据
      merchantData: [],
      options,
      disable: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      other: "",
      IMG_API: IMG_API,
      // 所有关键词组
      allKeyWordGroup: [],
      imgList: [],
      projectList: [],
      // 活动奖池配置
      jackpotConfigList: [],
      // pk活动配置
      activityConfig: [],
      // 是否展示活动配置弹窗
      isShowConfig: false,
      // 当前编辑的活动配置form
      currentEditConfigForm: '',
      // 当前编辑的活动配置下标
      currentEditConfigIndex: '',
      // 当前编辑配置信息
      currentEditConfig: {},
      // 可关联的奖池
      jackPotList: [],
      configForm: {
        projectId: "",
        projectName: "",
        pondId: undefined,
        keywordId: "",
        activityConfigMerchantList: []
      },
      // 活动类型
      activitiesType,
      currentEditIndex: "",
      form: {
        id: "",
        // 活动类型 1、开工发财树，2、战队pk，3、年终活动 4、开工红包
        type: undefined,
        activityTitle: "",
        activityTime: [],
        startTime: "",
        endTime: "",
        shareImg: "",
        shareInfo: "",
        activityRule: "",
        theirProject: [],
        // 发财树配置
        moneyTreeConfig: [],
        // 开工红包配置信息
        startRedEnvelopeConfig: [],
        // 年终活动配置信息
        yearEndActivityConfig: [],
        // 活动奖池配置
        activityConfigList: [],
        // 活动配置
        activityConfigArray: [],
        // 战队PK活动配置
        teamPkConfig: [],
        // 话题ID
        topicId: '',
        // 商户范围
        merchants:[],
        // 参与条件（1：企业认证  2：当日通行信息  3：当日消费信息）
        takeCondition: [],
        status: 0
      },
      rules: {
        type: [
          { required: true, message: '请选择活动类型', trigger: 'change' }
        ],
        activityTitle: [
          { required: true, message: "请输入活动名称", trigger: "blur" }
        ],
        activityTime: [
          { required: true, message: "请选择活动有效期", trigger: "change" }
        ],
        shareImg: [
          { required: true, message: "请上传活动分享图片", trigger: "change" }
        ],
        shareInfo: [
          { required: true, message: "请填写分享简介", trigger: "change" }
        ],
        activityRule: [
          { required: true, message: "请输入活动规则", trigger: "blur,change" }
        ],
        theirProject: [
          { required: true, message: "请选择所属项目", trigger: "change" }
        ],
        // takeCondition: [
        //   { required: true, message: "请选择活动参与条件", trigger: "change" }
        // ],
        status: [
          { required: true, message: "请选择状态", trigger: "change" }
        ],
        activityConfigArray: [
          { required: true, message: "请输入活动配置", trigger: "blur,change" }
        ],
        topicId: [
          { required: true, message: "请输入指定的i友圈话题ID", trigger: "blur" }
        ],
        merchants: [
          { required: true, message: "请上传商户范围", trigger: "blur,change" }
        ],
        moneyTreeConfig: [
          { required: true, message: "请配置活动信息配置", trigger: "blur,change" }
        ],
        startRedEnvelopeConfig: [
          { required: true, message: "请配置活动信息配置", trigger: "blur,change" }
        ],
        teamPkConfig: [
          { required: true, message: "请配置活动配置", trigger: "blur,change" }
        ],
        yearEndActivityConfig: [
          { required: true, message: "请配置活动配置", trigger: "blur,change" }
        ]
      }
    };
  },
  async created() {
    // 获取项目列表
    this.getProjectList();
  },
  mounted() {
    this.form.id = this.$route.query.id;
    if (this.$route.query.type === "view") {
      this.title = "查看";
      this.disable = true;
      this.form.id = this.$route.query.id;
    }
    if (this.$route.query.type === "edit") {
      this.title = "编辑";
      this.form.id = this.$route.query.id;
    }
    if (this.form.id) {
      this.findOne(this.form.id)
    }
  },
  watch: {
    'form.type': {
      handler(val) {
        if(val !== 0) {
          return false;
        }
        setTimeout(() => {
          this.createEditor()
        }, 200)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 下载模板
    downloadTemplate() {
      location.href = "https://oss-workplace-prod.innoecos.cn/picture_prod/0606cd6834810000_%E5%95%86%E6%88%B7%E4%BF%A1%E6%81%AF.xlsx";
    },
    // 上传excel并解析数据
    handleChangeFile({ file }) {
      const reader = new FileReader();
      reader.onload = () => {
        const txt = reader.result;
        const wb = XLSX.read(txt, {
          type: "binary"
        });
        const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        // 到json就可以获取到解析后的结果了
        const newData = json.map(item => {
          return {
            merchantId: item.商户id,
            merchantName: item.商户名称
          };
        });
        try {
          if (newData && newData.length === 0) {
            this.$message.error("excel没有读到数据!")
            throw new Error()
          }
          newData.forEach(item => {
            if (!item.merchantId && !item.merchantName) {
              this.$message.error("excel数据不正确！")
              throw new Error()
            }
          })
        } catch (err) {
          return false;
        }
        // 如果是战队PK
        if(this.form.type === 1) {
          this.form.merchants = newData
        } else {
          this.merchantData = newData;
          this.configForm.activityConfigMerchantList = this.merchantData;
        }
      };
      if (file.status === "error") {
        reader.readAsBinaryString(file.originFileObj);
      }
    },
    // 获取所有项目
    getProjectList() {
      projectName().then(res => {
        if (res.code === "200") {
          this.projectList = res.data.items;
          if (this.form.id) {
            this.findOne(this.form.id);
          }
        }
      });
    },
    // 查询详情
    findOne(id) {
      selectYearEndActivityById(id).then(res => {
        const { code, data, msg } = res
        if (code === "200") {
          this.imgList = [{
            uid: "-1",
            name: "image.png",
            status: "done",
            url: res.data.shareImg
          }];
          let newData = data;
          if (res.data.startTime && res.data.endTime) {
            newData.activityTime = [moment(res.data.startTime).format('YYYY-MM-DD HH:mm:ss'), moment(res.data.endTime).format('YYYY-MM-DD HH:mm:ss')];
          }
          newData.takeCondition = res.data.takeCondition.split(",");
          newData.theirProject = res.data.theirProject.split(",");
          const configList = newData.type === 1 ? newData.activityConfigArray : newData.activityConfigList
          // 查找项目名称并赋值
          const newConfigList = configList.map(item => {
            return {
              ...item,
              projectName: this.projectList.find(arr => arr.id === item.projectId).project_name
            }
          })
          // 开工发财树
          if(newData.type === 2) {
            newData.moneyTreeConfig = JSON.parse(JSON.stringify(newConfigList))
            this.activitiesConfig = JSON.parse(JSON.stringify(newConfigList))
          } else if(newData.type === 3) {
            newData.startRedEnvelopeConfig = JSON.parse(JSON.stringify(newConfigList))
            this.activitiesConfig = JSON.parse(JSON.stringify(newConfigList))
          } else if(newData.type === 1) {
            newData.teamPkConfig = JSON.parse(JSON.stringify(newConfigList))
            this.activitiesConfig = JSON.parse(JSON.stringify(newConfigList))
          } else if(newData.type === 0) {
            newData.yearEndActivityConfig = JSON.parse(JSON.stringify(newConfigList))
            this.activitiesConfig = JSON.parse(JSON.stringify(newConfigList))
          }
          this.form = newData;
        }
      });
    },
    onSubmit() {
      // 检查项目奖池&关键词配置信息
      if (!this.checkData()) {
        return false;
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let newData = {};
          newData = JSON.parse(JSON.stringify(this.form));
          // 处理商户数据
          newData.merchants = newData.merchants && newData.merchants.length && newData.merchants.map(item => {
            return {
              ...item,
              projectName: item.projectName.split('-').length > 1 ? item.projectName : `${item.projectId}-${item.projectName}`
            }
          })
          newData.theirProject = this.form.theirProject.toString();
          newData.takeCondition = this.form.takeCondition.toString();
          newData.startTime = moment(this.form.startTime, "YYYY-MM-DD HH:mm:ss").valueOf();
          newData.endTime = moment(this.form.endTime, "YYYY-MM-DD HH:mm:ss").valueOf();
          if (!this.form.id) {
            insertYearEndActivity(newData).then(res => {
              if (res.code === "200") {
                this.$message.success("操作成功！");
                this.$router.back();
              } else {
                this.$message.warning(res.msg);
              }
            });
          } else {
            updateYearEndActivity(newData).then(res => {
              if (res.code === "200") {
                this.$message.success("操作成功！");
                this.$router.back();
              } else {
                this.$message.warning(res.msg);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    checkData() {
      const newActivitiesConfig = JSON.parse(JSON.stringify(this.activitiesConfig))
      try {
        // 开工发财树
        if(this.form.type === 2) {
            this.form.moneyTreeConfig = newActivitiesConfig
            if (!this.form.moneyTreeConfig.length) {
              this.$message.error("请配置活动信息配置");
              throw Error();
            }
            this.form.moneyTreeConfig.map(item => {
              if(!item.levelTop || !item.levelA || !item.levelB || !item.levelC || !item.levelD || !item.levelE || !item.rule) {
                this.$message.error(`请配置${item.projectName}的活动信息配置！`)
                throw Error();
              }
            })
            // 赋值
            this.form.activityConfigList = this.form.moneyTreeConfig
        } else if(this.form.type === 3) {
          this.form.startRedEnvelopeConfig = newActivitiesConfig
          if (!this.form.startRedEnvelopeConfig.length) {
            this.$message.error("请配置活动信息配置");
            throw Error();
          }
          this.form.startRedEnvelopeConfig.map(item => {
            if(!item.pondId || !item.rule) {
              this.$message.error(`请配置${item.projectName}的活动信息配置！`)
              throw Error();
            }
          })
          // 赋值
          this.form.activityConfigList = this.form.startRedEnvelopeConfig
        } else if(this.form.type === 1) {
          this.form.teamPkConfig = newActivitiesConfig
          if (!this.form.teamPkConfig.length) {
            this.$message.error("请配置活动信息配置");
            throw Error();
          }
          this.form.teamPkConfig.map(item => {
            if(!item.enthusiasmNum || !item.sagacityNum || !item.warmNum || !item.activityRule) {
              this.$message.error(`请配置${item.projectName}的活动信息配置！`)
              throw Error();
            }
          })
          // 赋值
          this.form.activityConfigArray = this.form.teamPkConfig
        } else if(this.form.type === 0) {
          this.form.yearEndActivityConfig = newActivitiesConfig
          console.log(this.form.yearEndActivityConfig, 'yearEndActivityConfig')
          if (!this.form.yearEndActivityConfig.length) {
            this.$message.error("请配置奖池&关键词配置");
            throw Error();
          }
          this.form.yearEndActivityConfig.map(item => {
            if(!item.projectId || !item.pondId) {
              this.$message.error(`请配置${item.projectName}的活动信息配置！`)
              throw Error();
            }
          })
          // 赋值
          this.form.activityConfigList = this.form.yearEndActivityConfig
        }
        return true;
      } catch (error) {
        return false;
      }
    },
    activityTimeChange(date, dataString) {
      this.form.startTime = dataString[0];
      this.form.endTime = dataString[1];
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 图片上传
    handleChangeImg({ fileList }) {
      this.imgList = fileList;
      if (this.imgList.length > 0 && this.imgList[0].status === "done") {
        this.form.shareImg = fileList[0].response.redirect_uri;
      }
      if (this.imgList.length === 0) {
        this.form.shareImg = "";
      }
    },
    /**
     * 上传商户信息
     */
    getMerchantData(data) {
      if(data && data.length) {
        this.form.merchants = data
      }
    },
    //上传文件之前校验图片大小
    imgBeforeUpload(file) {
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === "png" || type === "jpg";
      if (!isJPG) {
        this.$message.error("上传图片只能是jpg、png格式!");
        return new Promise(function(resolve, reject) {
          return reject;
        });
      }
      if (file.size / 1024 > 200) {
        this.$message.error("图片大小不能超过200KB!");
        return new Promise(function(resolve, reject) {
          return reject;
        });
      }
      const isSize = new Promise(function(resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function() {
          const valid = img.width === img.height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          this.checkSize = false;
          return reject();
        }
      );
      return isJPG && isSize;
    },
    onChange(checkedValues) {
    },
    projectOnChange(ids) {
      const copyActivitiesConfig = JSON.parse(JSON.stringify(this.activitiesConfig))
      let newActivitiesConfig = []
      ids.forEach((item, index) => {
        let project = this.projectList.find(arr => arr.id === item);
        if (project) {
          const config = copyActivitiesConfig.find(arr => arr.projectId === project.id);
          if (config) {
            newActivitiesConfig.push(config)
          } else {
            newActivitiesConfig.push({
              projectId: project.id,
              projectName: project.project_name,
            });
          }
        }
      });
      this.activitiesConfig = newActivitiesConfig;
    },

    /**
     * 开工发财树信息编辑按钮
     */
    startMoneyTreeEdit(item,index) {
      this.currentEditIndex = index
      this.currentEditConfig = JSON.parse(JSON.stringify(item))
      this.isShowConfig = true
    },
    /**
     * 开工红包信息配置
     */
    startRedEnvelopeEdit(item, index) {
      this.currentEditIndex = index
      this.currentEditConfig = JSON.parse(JSON.stringify(item))
      this.isShowConfig = true
    },
    /**
     * 年终活动编辑
     */
    yearEndActivityEdit(item, index) {
      this.currentEditIndex = index
      this.currentEditConfig = JSON.parse(JSON.stringify(item))
      this.isShowConfig = true
    },
    /**
     * 配置项目战队人数
     */
    editActivityConfig(item, index) {
      this.currentEditIndex = index
      this.currentEditConfig = JSON.parse(JSON.stringify(item))
      this.isShowConfig = true
    },
    /**
     * 关闭弹窗
     */
    closeModel() {
      this.isShowConfig = false
    },

    /**
     * 根据项目id获取可关联奖池
     */
    async getJackpotListByProjectId(projectId) {
        const res = await selectJackpotListByProjectId(projectId)
        const { data, code } = res
        if(code === '200') {
          this.jackPotList = data && data.length > 0 && data.map(item => {
              return {
                ...item,
                name: `${item.id} - ${item.pondTitle}`
              }
          })
        }
    },
    handleOk(e) {
      this.$refs.configForm.validate(valid => {
        if (valid) {
          this.visible = false;
          let keyWordGroupName = ""
          const newData = { ...this.configForm };
          if (newData.keywordId) {
            keyWordGroupName = this.allKeyWordGroup.find(item => newData.keywordId === item.id).keyWordGroupName;
          }
          this.configForm.keyWordGroupName = keyWordGroupName;
          this.jackpotConfigList.splice(this.currentEditIndex, 1, this.configForm);
        } else {
          return false;
        }
      });
    },
    /**
     * 添加发财树活动的数据
     */
    addStartMoneyTreeData(data) {
      this.form.moneyTreeConfig.splice(this.currentEditIndex, 1, data);
      this.activitiesConfig.splice(this.currentEditIndex, 1, data)
    },
    /**
     * 添加开工红包活动的数据
     */
    addRedEnvelopeData(data) {
      this.form.startRedEnvelopeConfig.splice(this.currentEditIndex, 1, data);
      this.activitiesConfig.splice(this.currentEditIndex, 1, data)
    },
    /**
     *  添加年终活动配置信息
     */
    addYearEndConfigData(data) {
      this.form.yearEndActivityConfig.splice(this.currentEditIndex, 1, data);
      this.activitiesConfig.splice(this.currentEditIndex, 1, data)

    },
    /**
     * 添加战队pk配置数据
     */
    addActivityData(data) {
      this.closeActivityConfig()
      this.form.teamPkConfig.splice(this.currentEditIndex, 1, data);
      this.activitiesConfig.splice(this.currentEditIndex, 1, data)
    },
    // 查询所有关键词列表
    findKeyWordGroupList(projectId) {
      selectKeyWordGroupList(projectId).then(res => {
        if (res.code === "200") {
          this.allKeyWordGroup = res.data;
        }
      });
    },
    back() {
      this.$router.back();
    },
    createEditor() {
      const _this = this;
      const editor = new wangEditor(`#editer`);
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.form.activityRule = newHtml;
      };
      editor.config.menus = [
        "fontName",
        "fontSize",
        "foreColor",
        "backColor",
        "underline",
        "italic",
        "bold",
        "justify",
        "splitLine",
        "undo",
        "redo",
        "list",
        "image",
        "video"
      ];
      editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgServer = this.IMG_API + "/oss/files";
      editor.config.uploadImgShowBase64 = false;
      editor.config.showLinkImg = false;
      editor.config.showLinkVideo = false;
      editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
      editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
      editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
      // editor.config.uploadVideoAccept = ['mp4']
      editor.config.uploadFileName = "file";
      editor.config.uploadVideoName = "file";
      editor.config.uploadVideoTimeout = 60000;
      editor.config.uploadImgTimeout = 60000;
      editor.config.uploadVideoHooks = {
        // 上传视频之前
        before: function(xhr, editor, files) {
          console.log(xhr);
          console.log(editor);
          console.log(files);
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function(xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function(xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function(xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function(xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function(insertVideoFn, result) {
          let videoHTML =
            "&nbsp;<video poster=\"" +
            result.redirect_uri +
            "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto\" src=\"" +
            result.redirect_uri +
            "\" controls style=\"max-width:100%\"></video>&nbsp;";
          editor.cmd.do("insertHTML", videoHTML);
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          // insertVideoFn(result.redirect_uri)
        }
      };
      editor.config.uploadImgHooks = {
        // 上传视频之前
        before: function(xhr) {
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function(xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function(xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function(xhr, editor, resData) {
          _this.$message.error("上传请求失败");
        },
        // 上传视频超时
        timeout: function(xhr) {
          _this.$message.error("上传请求超时");
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function(insertVideoFn, result) {
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          insertVideoFn(result.redirect_uri);
        }
      };
      editor.config.customAlert = function(s, t) {
        switch (t) {
          case "success":
            _this.$message.success(s);
            break;
          case "info":
            _this.$message.info(s);
            break;
          case "warning":
            _this.$message.warning(s);
            break;
          case "error":
            _this.$message.error(s);
            break;
          default:
            _this.$message.info(s);
            break;
        }
      };
      editor.config.placeholder = "请输入";
      editor.config.zindex = -1;
      // 创建编辑器
      editor.create();
      this.editor = editor;
      setTimeout(() => {
        this.editor.txt.html(this.form.activityRule);
      }, 1000);
      // 查看的时候禁用富文本
      if (this.$route.query.type === "view") {
        this.editor.disable();
      } else {
        this.editor.enable();
      }
    },
    /**
     * 关闭战队pk配置弹窗
     */
    closeActivityConfig() {
      this.isShowConfig = false
    }
  }
};
</script>

<style lang="scss" scoped>
.activity-config-box {
  display: flex;
  flex-wrap: wrap;

  & > ul {
    border: 1px solid #EBEBEB;
    min-width: 300px;
    margin: 10px 10px 0px 0px;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    position: relative;
    min-height: 100px;
    .config-but {
      position: absolute;
      bottom: 0;
      width: 90%;
      background: #FFFFFF;
    }
  }

  & > ul li {
    list-style-type: none;
    line-height: 25px;
    margin-left: -25px;
  }

  & > ul li:nth-child(4) {
    display: flex;
    justify-content: right;
    margin-right: 5px;
  }
  .rule-box {
    max-height: 300px;
    max-width: 300px;
    overflow: auto;

  }
}

#editer {
  z-index: -1 !important; /* 按需定义 */
}

.content {
  z-index: -1 !important;
}

.ant-modal-content {
  z-index: 30000;
}

</style>
